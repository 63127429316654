import React from "react"
import Swiper from 'react-id-swiper';
import {useStaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"
import GenButton from '../components/gen-button'
import PropTypes from "prop-types"
import Header from '../components/header'
import * as mainsliderstyles from "./main-slider.module.css"


const Btns = ({btncontent}) => {
    if(btncontent.length > 0){
        return(
            <div>
                {
                    btncontent.map((el, index) => (
                        <GenButton key={index} link={el.link} button={el.button} rel={el.rel} target={el.targetLink} />
                    ))
                }
            </div>
        )
    } else {
        return null
    }
}

export default function MainSlider({slider, classid, direction, autoplay, background}) {
    const data = useStaticQuery(graphql`
        query{
            slideimg: allFile(filter: {relativePath: {regex: "/images\/slides/"}}) {
                edges {
                    node {
                        relativePath
                        name
                        childImageSharp {
                            fluid(maxWidth: 400, maxHeight: 250) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    `)

    let params;
    
    if(autoplay) {
        params = {
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: true
            },
            autoplay: {
                delay: 4500,
                disableOnInteraction: false
              },
            loop: false,
            spaceBetween: 30
          }
    } else {
        params = {
            pagination: {
              el: '.swiper-pagination',
              clickable: true,
              dynamicBullets: true
            },
            spaceBetween: 30
          }
    }

    

    let backImage, slides;
    slides = [];
   slider.forEach((el, index) => {
       let curSlide;
       if(el.image.length > 0) {
           let imgSource = el.image;
           const image = data.slideimg.edges.find(n => {
               return n.node.relativePath.includes(imgSource)
           })

           if(!image) {
               return null;
           }

           backImage = <div className={mainsliderstyles.backimage}>
               <Img fluid={image.node.childImageSharp.fluid} />
           </div>
       }
        curSlide = <div key={index} className={mainsliderstyles.mainslidercontainer} style={{background: el.color_background }}>
            {backImage}
            <div className={mainsliderstyles.sliderbgdarktransparent}></div>
            <div className={mainsliderstyles.slidercontainer} style={{backgroundColor: background}}>
                <Header headerText={el.title} classTag="h3" headingTag="h3" />
                <p>{el.content}</p>
                <Btns btncontent={el.buttons} />
            </div>
        </div>
        slides.push(curSlide)
   })

      return (
        <div className={mainsliderstyles.mainslider}>
            <Swiper {...params}>
                {slides}
            </Swiper>
        </div>
      )
}

MainSlider.propTypes = {
    slider: PropTypes.array,
    classid: PropTypes.string,
    direction: PropTypes.string,
    background: PropTypes.string,
    autoplay: PropTypes.bool
}

MainSlider.defaultProps = {
    autoplay: false,
    background: "#ffffff"
}

