import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import CardGroup from "../components/card-group-expanded"
import SEO from "../components/seo"
import MainSlider from "../components/main-slider"
import GenForm from "../components/gen-contact-form"
import WaysToOrder from "../components/ways-to-order"
import CompareKitsSection from "../components/compare-kits-section"

// import VideoHeader from "../components/video-header"

export default function Home({ data }) {
  const pageTitle = "Home Page"
  const pageDescription = "Home Page. Independent Forensics is a forensic DNA laboratory that provides DNA testing and conceives, develops, perfects, manufactures, markets and sells new products to forensic laboratories worldwide."
  
  const classIdentifier = "mainHomeSlider"
  const sliderDirection = "horizontal"
  const slides = [
    {
      title: "DNA Forensic Services",
      content: "Fully accredited laboratory provides Forensic DNA testing to law enforcement, defense, prosecution, and private clients.",
      image: "home-slide-crime-1200px.jpg",
      color_background: 'lightblue',
      container_color: "white",
      text_align: "",
      buttons: [
        {
          link: "forensics/",
          button: "View Services",
          rel: "noopener",
          targetLink: "_self",
        }
      ],
    },
    {
      title: "DNA Testing Services",
      content: "DNA Immigration & Paternity Testing. Pain Free. Affordable. Confidential. Reliable.",
      image: "home-slide-paternity-1000px.jpg",
      color_background: 'lightblue',
      container_color: "white",
      text_align: "",
      buttons: [
        {
          link: "dnaservices/",
          button: "Learn More",
          rel: "noopener",
          targetLink: "_self",
        },
      ],
    },
    {
      title: "DNA Immigration Services",
      content: "USCIS/US Embassy. Foreign Birth Abroad. US State Department. AABB Accredited.",
      image: "home-slide-immigration-1000px.jpg",
      color_background: 'lightblue',
      container_color: "white",
      text_align: "",
      buttons: [
        {
          link: "dna-immigration-home/",
          button: "Learn More",
          rel: "noopener",
          targetLink: "_self",
        },
      ],
    },
    {
      title: "DNA Expert Witness",
      content: "Detailed case review and expert witness testimony.",
      image: "witness01.jpg",
      color_background: 'lightblue',
      container_color: "white",
      text_align: "",
      buttons: [
        {
          link: "expert-witness/",
          button: "Learn More",
          rel: "noopener",
          targetLink: "_self",
        },
      ],
    },
    {
      title: "Rapid Stain ID",
      content: "Process sexual assault evidence better! Bulk & Educational Discounts Available",
      image: "2022-rsid-banner-1200px.jpg",
      color_background: 'lightblue',
      container_color: "white",
      text_align: "",
      buttons: [
        {
          link: "rsid/",
          button: "RSID kits",
          rel: "noopener",
          targetLink: "_self",
        },
      ],
    }
  ];

  return (
    <Layout>
      <SEO title={pageTitle} description={pageDescription} />
      <MainSlider slider={slides} autoplay={true} classid={classIdentifier} direction={sliderDirection} />
      {/* <VideoHeader /> */}
      {/* <CardGroup services={data.allQuickLinksJson.edges} title="Order Lab Products" description="" /> */}
      <WaysToOrder />
      <CompareKitsSection />
      <CardGroup services={data.allServiceCatJson.edges} title="Services" description=""  />
      <CardGroup services={data.allProductCatJson.edges} title="Products" description=""  />
      <GenForm />
    </Layout>
  )
}

export const query = graphql `
  query {
    allServiceCatJson {
      edges {
        node {
          id
          image
          title
          excerpt
          buttons{
            link
            button
            rel
            targetLink
          }
        }
      }
    }
    allProductCatJson {
      edges {
        node {
          id
          image
          title
          excerpt
          buttons{
            link
            button
            rel
            targetLink
          }
        }
      }
    }
    allQuickLinksJson {
      edges {
        node {
          id
          image
          title
          excerpt
          buttons{
            link
            button
            rel
            targetLink
          }
        }
      }
    }
  }
`