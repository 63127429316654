import React from 'react'
import GenButton from './gen-button'
import Container from "../components/container"
import Header from "../components/header"
import * as compareKits from "./compare-kits-section.module.css"

const CompareKitsSection = function() {
    return(
        <section className="medium">
            <Container>
                <div className={compareKits.flexcontainer}>
                    <Header headerText='Compare RSID Kits' classTag='h2' headingTag='h2' />
                    <div><p>Compare details of the RSID Kits side by side.</p></div>
                    <GenButton button="Learn More" link="compare/" color="white" />
                </div>
            </Container>
        </section>
    )
}

export default CompareKitsSection;