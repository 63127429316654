import React from 'react'
import Container from "../components/container"
import Header from "../components/header"
import GenButton from "../components/gen-button"
import * as waystyles from "./ways-to-order.module.css"

const WaysToOrder = function () {
    return (
        <section className="medium">
            <Container>
                <Header headerText="Order Our Products" headingTag="h2" classTag="h2 center-text"/>
                <p></p>
                <div className={waystyles.flexcontainer}>
                    <div className="center-text shadow">
                        <Header headerText="Shopify Store" headingTag="h3" classTag="h4" />
                        <GenButton color="white" button="Browse" link="https://store.ifi-test.com/collections" target="_blank" />
                    </div>
                    <div className="center-text shadow">
                        <Header headerText="Product List/Online Order Form" headingTag="h3" classTag="h4" />
                        <GenButton color="white" button="Browse" link="shop/" />    
                    </div>
                </div>
                <div className={[waystyles.thirdcol, "center-text", "shadow"].join(" ")}>
                    <Header headerText="Download editable order form. Email it at your convenience." headingTag="h3" classTag="h4" />
                    <GenButton color="white" button="Download" link="order-form/" />
                </div>
            </Container>
        </section>
    )
}

export default WaysToOrder