import React from 'react'
import Card from '../components/card-expanded'
import Header from '../components/header'
import * as cardGroupExtendedStyles from './card-group-expanded.module.css'

export default function CardGroup({services, title, description}) {
    let cards = []
    function createMarkup() {
        return {__html: description}
    }

    if(services[0].hasOwnProperty('node')) {
        services.forEach(({ node }, index) => {
            cards.push(<Card key={index} title={node.title} excerpt={node.excerpt} image={node.image} buttons={node.buttons} />)
        })
    } else {
        services.forEach((node, index) => {
            cards.push(<Card key={index} title={node.title} excerpt={node.excerpt} image={node.image} buttons={node.buttons} html={node.show_full_excerpt} />)
        })
    }

 return (
    <section className="medium">
        <div className="center-text">
            <Header headerText={title} headingTag="h2" />
            <p dangerouslySetInnerHTML={createMarkup()}></p>
        </div>
        <div className={cardGroupExtendedStyles.cardgroup}>
            {cards}
        </div>   
    </section>
 )
}